export const SET_NEW_SMARTBOXID = "SET_NEW_SMARTBOXID"
export const CARTAVLOADING = "CARTAVLOADING"
export const CARTAVNOTLOADING = "CARTAVNOTLOADING"
export const CARTLOCKLOADING = "CARTLOCKLOADING"
export const CARTLOCKNOTLOADING = "CARTLOCKNOTLOADING"
export const SELECTAVCART = "SELECTAVCART"
export const UNSELECTAVCART = "UNSELECTAVCART"
export const UNSELECT_BOOKING = "UNSELECT_BOOKING"
export const SELECT_BOOKING = "SELECT_BOOKING"
export const CHANGECART = "CHANGECART"
export const SETREADING = "SETREADING"
export const SETBOOKING = "SETBOOKING"
export const UNSETBOOKING = "UNSETBOOKING"
export const SET_NEXT_BOOKING_OF_CART = "SET_NEXT_BOOKING_OF_CART"
export const UNSET_NEXT_BOOKING_OF_CART = "UNSET_NEXT_BOOKING_OF_CART"
export const SETMOBILEUSER = "SETMOBILEUSER"
export const SETUSERSWPERMISSION = "SETUSERSWPERMISSION"
export const UNSETUSERSWPERMISSION = "UNSETUSERSWPERMISSION"
export const SETTALLSETTINGS = "SETTALLSETTINGS"
export const SELECTSETTINGSFIELD = "SELECTSETTINGSFIELD"
export const SETSETTINGSTIME = "SETSETTINGSTIME"
export const SETSETTINGSTIMEUPDATED = "SETSETTINGSTIMEUPDATED"
export const SELECTSETTINGSCLASS = "SELECTSETTINGSCLASS"
export const SELECTSETTINGSCLASSPRICE = "SELECTSETTINGSCLASSPRICE"
export const SELECTSETTINGSCLASSPRICEPPM = "SELECTSETTINGSCLASSPRICEPPM"
export const SETTINGSSETTALLCLASSES = "SETTINGSSETTALLCLASSES"
export const SETUSERFORPERMISSION = "SETUSERFORPERMISSION"
export const SETPERMISSION = "SETPERMISSION"
export const SETUSERACCESSPERMISSION = "SETUSERACCESSPERMISSION"
export const CLEARUSERPERMISSIONEDIT = "CLEARUSERPERMISSIONEDIT"
export const SETPERMISSIONEMAIL = "SETPERMISSIONEMAIL"
export const SETENGINE = "SETENGINE"
export const SELECTRAINDAY = "SELECTRAINDAY"
export const COLLECTBOOKINGLOADING = "COLLECTBOOKINGLOADING"
export const COLLECTBOOKINGDONE = "COLLECTBOOKINGDONE"
export const SETRAINMESSAGE = "SETRAINMESSAGE"
export const UNSELECTRAINDAY = "UNSELECTRAINDAY"
export const CHANGERAINTIME = "CHANGERAINTIME"
export const USER_LOG_OUT = "USER_LOG_OUT"
export const SETINACTIVECOUPONS = "SETINACTIVECOUPONS"
export const SETACTIVECOUPONS = "SETACTIVECOUPONS"
export const UPDATECREATEFORM = "UPDATECREATEFORM"
export const CLEARCREATEFORM = "CLEARCREATEFORM"
export const COUPONADDSEGMENT = "COUPONADDSEGMENT"
export const COUPONREMOVESEGMENT = "COUPONREMOVESEGMENT"
export const SETACTIVECOUPONSLOADED = "SETACTIVECOUPONSLOADED"
export const SETINACTIVECOUPONSLOADED = "SETINACTIVECOUPONSLOADED"
export const SETACTIVECOUPONSNOTLOADED = "SETACTIVECOUPONSNOTLOADED"
export const SETINACTIVECOUPONSNOTLOADED = "SETINACTIVECOUPONSNOTLOADED"
export const COUPONCHANGE = "COUPONCHANGE"
export const COUPONDELETE = "COUPONDELETE"
export const PERMISSIONSSHOULDUPDATESTATE = "PERMISSIONSSHOULDUPDATESTATE"
export const PAGE_CHANGE_SETUP_FLOW = "PAGE_CHANGE_SETUP_FLOW"
export const COMPLETE_SETUP = "COMPLETE_SETUP"
export const COMPLETE_STEP = "COMPLETE_STEP"
export const PAGE_CHANGE_ACADEMY = "PAGE_CHANGE_ACADEMY"
export const COMPLETE_ACADEMY = "COMPLETE_ACADEMY"
export const SET_IMAGE = "SET_IMAGE"
export const UNSET_IMAGE = "UNSET_IMAGE"
export const SET_DETAILS_ALL_SETTINGS = "SET_DETAILS_ALL_SETTINGS"
export const SELECT_DETAIL_FIELD = "SELECT_DETAIL_FIELD"
export const PAGE_CHANGE_DETAIL_FLOW = "PAGE_CHANGE_DETAIL_FLOW"
export const PAGE_CHANGE_SETTING_FLOW = "PAGE_CHANGE_SETTING_FLOW"
export const SETTINGS_ERROR_SET = "SETTINGS_ERROR_SET"
export const SETTINGS_ERROR_AUTOLOCK = "SETTINGS_ERROR_AUTOLOCK"
export const ONBOARDING_SET_STRIPE_URL = "ONBOARDING_SET_STRIPE_URL"
export const ONBOARDING_SET_STRIPE_COMPLETED = "ONBOARDING_SET_STRIPE_COMPLETED"
export const SET_PERMISSION_INSTALLER_EMAIL = "SET_PERMISSION_INSTALLER_EMAIL"
export const SET_REDIRECT_ON_LOGIN = "SET_REDIRECT_ON_LOGIN"
export const UNSET_REDIRECT_ON_LOGIN = "UNSET_REDIRECT_ON_LOGIN"
export const SET_ALL_CARTS_TO_INSTALL = "SET_ALL_CARTS_TO_INSTALL"
export const SET_ALL_CARTS_INSTALL_STATUS = "SET_ALL_CARTS_INSTALL_STATUS"
export const SET_COMPLETE_CART = "SET_COMPLETE_CART"
export const SET_CART_STATUS = "SET_CART_STATUS"
export const SET_INSTALL_PAGE = "SET_INSTALL_PAGE"
export const SET_CARTID_TO_DOC_ID = "SET_CARTID_TO_DOC_ID"
export const SET_BOOKING_FIELDS = "SET_BOOKING_FIELDS"
export const CLEAR_CREATE_BOOKING = "CLEAR_CREATE_BOOKING"
export const SET_CART_TRIPS_BOOKING = "SET_CART_TRIPS_BOOKING"
export const SET_BOOKING_VIEW_START = "SET_BOOKING_VIEW_START"
export const SET_CURRENT_CART_INSTALL = "SET_CURRENT_CART_INSTALL"
export const SET_ALL_CARTS_INSTALLED = "SET_ALL_CARTS_INSTALLED"
export const UPDATE_BOOKING_VIEW = "UPDATE_BOOKING_VIEW"
export const SET_EDIT_COUPON = "SET_EDIT_COUPON"
export const RIDEPASSFORM_CLEAR = "RIDEPASSFORM_CLEAR"
export const RIDEPASS_ADD_SEGMENT = "RIDEPASS_ADD_SEGMENT"
export const RIDEPASS_REMOVE_SEGMENT = "RIDEPASS_REMOVE_SEGMENT"
export const RIDEPASS_CHANGE = "RIDEPASS_CHANGE"
export const SET_ACTIVE_RIDEPASS = "SET_ACTIVE_RIDEPASS"
export const SET_ACTIVE_RIDEPASS_LOADED = "SET_ACTIVE__RIDEPASS_LOADED"
export const SET_ACTIVE_RIDEPASS_NOT_LOADED = "SET_ACTIVE__RIDEPASS_NOT_LOADED"
export const SET_INACTIVE_RIDEPASS = "SET_INACTIVE_RIDEPASS"
export const SET_INACTIVE_RIDEPASS_LOADED = "SET_INACTIVE_RIDEPASS_LOADED"
export const SET_INACTIVE_RIDEPASS_NOT_LOADED =
    "SET_INACTIVE_RIDEPASS_NOT_LOADED"
export const UPDATECREATE_RIDEPASS_FORM = "UPDATECREATE_RIDEPASS_FORM"
export const SET_APPINFO_ALL = "SET_APPINFO_ALL"
export const SET_APPINFO_FIELD = "SET_APPINFO_FIELD"
export const SET_APPINFO_SAVED_ALL_CHANGES = "SET_APPINFO_SAVED_ALL_CHANGES"
export const SET_BOOKINGS_BOOKING_VIEW = "SET_BOOKINGS_BOOKING_VIEW"
export const ADD_BOOKINGS_BOOKING_VIEW = "ADD_BOOKINGS_BOOKING_VIEW"
export const EDIT_BOOKINGS_BOOKING_VIEW = "EDIT_BOOKINGS_BOOKING_VIEW"
export const REMOVE_BOOKINGS_BOOKING_VIEW = "REMOVE_BOOKINGS_BOOKING_VIEW"
export const SET_SERVICE_STATE = "SET_SERVICE_STATE"
export const SET_SERVICE_EXPANDED = "SET_SERVICE_EXPANDED"
export const SET_BOOKINGS_LOADING_BOOKING_VIEW =
    "SET_BOOKINGS_LOADING_BOOKING_VIEW"
export const SET_SETTINGS_SAVED = "SET_SETTINGS_SAVED"
export const SET_SEGMENT = "SET_SEGMENT"
export const DELETE_SEGMENT = "DELETE_SEGMENT"
export const SET_SELECTED_VEHICLE = "SET_SELECTED_VEHICLE"
export const SET_VEHICLE_NOTE = "SET_VEHICLE_NOTE"
export const SET_VEHICLE_NOTE_RELOAD = "SET_VEHICLE_NOTE_RELOAD"
export const SET_VEHICLE_RATING = "SET_VEHICLE_RATING"
export const SET_NOTIFICATION = "SET_NOTIFICATION"
export const SET_LOADING_CURRENT_BOOKING = "SET_LOADING_CURRENT_BOOKING"
export const SET_CURRENT_BOOKING = "SET_CURRENT_BOOKING"
export const SET_NEXT_BOOKING = "SET_NEXT_BOOKING"
export const SET_LOADING_NEXT_BOOKING = "SET_LOADING_NEXT_BOOKING"
export const SET_VEHICLE_QUERY = "SET_VEHICLE_QUERY"
export const SET_VEHICLE_BOOKINGS = "SET_VEHICLE_BOOKINGS"
export const SET_VEHICLE_STATS = "SET_VEHICLE_STATS"
export const SET_BOOKING_QUERY = "SET_BOOKING_QUERY"
export const SET_BOOKING_QUERY_EXECUTE = "SET_BOOKING_QUERY_EXECUTE"
export const SET_BOOKINGS = "SET_BOOKINGS"
export const SET_COUPON_HISTORY = "SET_COUPON_HISTORY"
export const SET_STATS = "SET_STATS"
export const SET_PERCENTAGE_VAT = "SET_PERCENTAGE_VAT"
export const SET_REGISTER_STEP = "SET_REGISTER_STEP"
export const SET_PAYMENT_MODEL = "SET_PAYMENT_MODEL"
export const SET_PRICE_MODEL = "SET_PRICE_MODEL"
export const SET_COMPLETED_COI = "SET_COMPLETED_COI"
export const SET_LOADED_COI = "SET_LOADED_COI"
export const SET_REPORT_LIMIT = "SET_REPORT_LIMIT"
export const SET_REPORTS = "SET_REPORTS"
export const SET_REPORTS_NOT_LOADED = "SET_REPORTS_NOT_LOADED"
