import makeStyles from "@mui/styles/makeStyles"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { LinearProgress } from "@mui/material"
import theme from "../../assets/styles/muiTheme"
import SettingTopbar from "../settingsTabs/settingTab"
import {
    SETACTIVECOUPONS,
    SETACTIVECOUPONSLOADED,
    SETINACTIVECOUPONS,
    SETINACTIVECOUPONSLOADED,
} from "../../constants/actionTypes"
import store from "../../store"
import CouponGrid from "../CouponGrid"
import CouponCard, { COUPONCARDWIDTH } from "../CouponCard"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"

export const useCouponListStyles = makeStyles((theme) => ({
    root: {
        height: `${100}%`,
    },
}))
const couponOptions = ["Active Discounts", "Inactive Discounts"]

export function LinearLoader({ load }) {
    if (load) {
        return <LinearProgress color="secondary" />
    }
    return null
}
export function CouponLoader({
    active,
    activeCouponsLoaded,
    inactiveCouponsLoaded,
}) {
    if (active && !activeCouponsLoaded) {
        return <LinearLoader load={!activeCouponsLoaded} />
    }
    if (!active && !inactiveCouponsLoaded) {
        return <LinearLoader load={!inactiveCouponsLoaded} />
    }
    return null
}

export default function CouponsList(props) {
    const classes = useCouponListStyles(theme)
    const coupons = useSelector((state) => state.coupons)
    const getAllCoupons = httpsCallable(functions, "getAllCoupons")
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })
    useEffect(() => {
        if (!coupons.activeCouponsLoaded) {
            console.log("get all active")

            getAllCoupons({
                courseDocId,
                active: true,
                startTime: Date.now(),
            })
                .then((r) => {
                    console.log(r)
                    store.dispatch({
                        type: SETACTIVECOUPONSLOADED,
                        payload: { activeCoupons: r.data },
                    })
                })
                .catch((e) => {
                    console.error(e.message)
                })
        }
    }, [coupons.activeCouponsLoaded, getAllCoupons])
    useEffect(() => {
        if (!coupons.inactiveCouponsLoaded) {
            getAllCoupons({
                courseDocId,
                active: false,
                startTime: Date.now(),
            })
                .then((r) => {
                    console.log(r)
                    store.dispatch({
                        type: SETINACTIVECOUPONSLOADED,
                        payload: { inactiveCoupons: r.data },
                    })
                })
                .catch((e) => {
                    console.error(e.message)
                })
        }
    }, [coupons.inactiveCouponsLoaded, getAllCoupons])

    const handleChange = (event, newValue) => {
        if (newValue === 0) {
            store.dispatch({ type: SETACTIVECOUPONS })
        } else {
            store.dispatch({ type: SETINACTIVECOUPONS })
        }
    }
    return (
        <div className={classes.root}>
            <CouponLoader
                active={coupons.active}
                activeCouponsLoaded={coupons.activeCouponsLoaded}
                inactiveCouponsLoaded={coupons.inactiveCouponsLoaded}
            />
            <SettingTopbar
                settings={couponOptions}
                allSettings={couponOptions}
                settingFocused={coupons.active ? 0 : 1}
                handleChange={handleChange}
                variant="fullWidth"
            />
            <CouponGrid
                Component={CouponCard}
                couponList={
                    coupons.active
                        ? coupons.activeCoupons
                        : coupons.inactiveCoupons
                }
                cardWidth={COUPONCARDWIDTH}
            />
        </div>
    )
}
